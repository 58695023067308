<template>
  <div>
    <SummaryElement :read-only="readOnly" v-bind="$attrs" v-on="$listeners" />
    <div v-if="isScheduleActive" class="px-4 pt-3 mb-2">
      <v-chip
        v-for="(item, key) in sortedSchedule"
        :key="key"
        label
        small
        class="mr-1 mb-2"
      >
        <b>{{ getShortKey(key) }}</b>
        &nbsp;
        <span>{{ getScheduleLabel(item) }}</span>
      </v-chip>
      <v-dialog v-if="!readOnly" v-model="dialog" width="500" persistent>
        <template #activator="{ on, attrs }">
          <v-chip
            color="primary"
            label
            small
            class="ml-n1 mt-n2"
            v-bind="attrs"
            v-on="on"
          >
            Edit schedule
            <v-icon right>fas fa-pen</v-icon>
          </v-chip>
        </template>
        <v-card>
          <v-card-title>Power Schedule</v-card-title>
          <v-divider />
          <v-card-text class="pt-4">
            <ESchedulePicker
              v-model="schedule"
              :preload-schedule="schedule"
              :schedule-type="scheduleType"
            />
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey" text @click="resetSchedule"> Cancel </v-btn>
            <v-btn color="primary" text @click="updateSchedule">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import SummaryElement from "@/components/cameraTabs/summary/SummaryElement"
import { sortScheduleByDay } from "@evercam/shared/utils"
import { schedules, ScheduleType } from "@evercam/ui"

export default {
  name: "ScheduleSummaryElement",
  components: { SummaryElement },
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
    scheduleConfig: {
      type: [Object, String],
      default: () => ({}),
    },
  },
  data() {
    return {
      dialog: false,
      scheduleType: ScheduleType.Custom,
      schedule: null,
    }
  },
  computed: {
    isScheduleActive() {
      return this.$attrs?.value?.value
    },
    sortedSchedule() {
      return sortScheduleByDay(this.schedule)
    },
  },
  watch: {
    isScheduleActive(active) {
      if (!active) {
        this.$emit("update-schedule", {})

        return
      }

      if (!Object.keys(this.scheduleConfig || {})?.length) {
        this.schedule = schedules.continuous
        this.$emit("update-schedule", this.schedule)
      }
    },
    scheduleConfig: {
      handler() {
        this.schedule = this.getParsedSchedule()
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    getParsedSchedule() {
      return typeof this.scheduleConfig === "string"
        ? JSON.parse(this.scheduleConfig || "{}")
        : { ...this.scheduleConfig }
    },
    resetSchedule() {
      this.dialog = false
      this.schedule = this.getParsedSchedule()
    },
    updateSchedule() {
      this.dialog = false
      this.$emit("update-schedule", this.schedule)
    },
    getShortKey(key) {
      return key?.toUpperCase()?.[0] || ""
    },
    getScheduleLabel(item) {
      return item?.[0] || "N/A"
    },
  },
}
</script>
