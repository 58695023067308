<template>
  <ERow no-gutters>
    <ECol cols="auto" md="6" xs="12" class="d-flex h-100">
      <v-expansion-panels v-model="panel" flat accordion multiple>
        <ERow no-gutters>
          <!-- CAMERA DETAILS -->
          <ECol
            :class="{ 'w-100': $vuetify.breakpoint.xs }"
            cols="auto"
            xs="12"
            :sm="cameraDialogStore.isCleanMode ? 12 : 6"
            :md="cameraDialogStore.isCleanMode ? 12 : 6"
            :lg="cameraDialogStore.isCleanMode ? 12 : 6"
            :xl="cameraDialogStore.isCleanMode ? 12 : 6"
          >
            <v-card outlined class="d-flex h-100" rounded="0" elevation="0">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div class="d-flex align-center">
                    <span class="subtitle-2">Camera </span>
                    <v-tooltip bottom>
                      <template #activator="{ on, attrs }">
                        <v-img
                          :src="`${require('~/assets/img/camera.png')}`"
                          class="ml-auto"
                          width="24px"
                          maxWidth="24px"
                          height="100%"
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <span>
                        <v-img
                          :src="`${require('~/assets/img/camera.png')}`"
                          class="h-100 w-100"
                        />
                      </span>
                    </v-tooltip>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <!-- Vendor -->
                  <SummaryElement
                    v-model="cameraModel"
                    :read-only="!canEditCameras"
                  />
                  <!-- Name -->
                  <SummaryElement
                    v-model="name"
                    rules="required"
                    :read-only="!canEditCameras"
                  />

                  <!-- Project -->
                  <CameraSummaryProject
                    :camera="camera"
                    :read-only="!canEditCameras"
                  />

                  <!-- Camera Username -->
                  <SummaryElement
                    v-model="cameraUsername"
                    :read-only="!canEditCameras"
                  />

                  <!-- Camera Password -->
                  <SummaryElement
                    v-model="cameraPassword"
                    is-password
                    is-copyable
                    :read-only="!canEditCameras"
                  />
                  <!-- Host -->
                  <SummaryElement
                    v-model="cameraHost"
                    rules="required|ip_or_domain"
                    :read-only="!canEditCameras"
                  />

                  <!-- Camera Ports -->
                  <ERow no-gutters>
                    <div class="d-flex justify-start">
                      <div
                        class="summary-element__label subtitle-2 mr-2 pl-1 pt-1"
                      >
                        Ports
                      </div>
                      <div
                        :class="{
                          'd-flex':
                            $vuetify.breakpoint.xlOnly ||
                            $vuetify.breakpoint.smAndDown,
                        }"
                      >
                        <!-- Camera HTTP port -->
                        <CameraSummaryPortElement
                          v-model="cameraHttpPort"
                          :scheme="camera.cameraScheme"
                          :port="camera.cameraHttpPort"
                          :host="camera.cameraHost"
                          :read-only="!canEditCameras"
                          class="ml-n3"
                          dense
                        />

                        <!-- Camera RTSP Port -->
                        <CameraSummaryPortElement
                          v-model="cameraRtspPort"
                          :port="camera.cameraRtspPort"
                          :host="camera.cameraHost"
                          :read-only="!canEditCameras"
                          class="ml-n3"
                          dense
                        />
                      </div>
                    </div>
                  </ERow>
                  <div class="summary-element__label subtitle-2 mr-2 pl-1 pt-1">
                    Camera Location
                  </div>
                  <!-- Location: Lat, Lng -->
                  <SummaryElement
                    v-model="cameraLocation"
                    :read-only="!canEditCameras"
                  />
                  <!-- Camera Angle -->
                  <SummaryElement
                    v-model="cameraHeading"
                    :read-only="!canEditCameras"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-card>
          </ECol>

          <!-- NVR DETAILS -->
          <ECol
            v-if="!cameraDialogStore.isCleanMode"
            :class="{ 'w-100': $vuetify.breakpoint.xs }"
            cols="auto"
            xs="12"
            sm="6"
            md="6"
            lg="6"
            xl="6"
          >
            <v-card outlined class="d-flex h-100" rounded="0" elevation="0">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div class="d-flex align-center">
                    <span class="subtitle-2">NVR </span>
                    <v-tooltip bottom>
                      <template #activator="{ on, attrs }">
                        <v-img
                          :src="`${require('~/assets/img/nvr.png')}`"
                          class="ml-auto"
                          width="24px"
                          maxWidth="24px"
                          height="100%"
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <span>
                        <v-img
                          :src="`${require('~/assets/img/nvr.png')}`"
                          class="h-100 w-100"
                        />
                      </span>
                    </v-tooltip>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <!-- Vendor -->
                  <SummaryElement
                    v-model="nvrModel"
                    :has-default-save-callback="false"
                    :read-only="!canEditCameras"
                    @save="updateNvrConfig"
                  />

                  <!-- ExNvr Devices List -->
                  <CameraSummaryNvrDeviceId
                    v-if="camera.nvrModel === 'ex_nvr'"
                    :camera="camera"
                  />

                  <!-- Device ID / NVR Channel -->
                  <SummaryElement
                    v-else
                    v-model="deviceId"
                    :has-default-save-callback="false"
                    :read-only="!canEditCameras"
                    is-copyable
                    @save="updateNvrConfig"
                  />

                  <!-- Host -->
                  <SummaryElement
                    v-model="nvrHost"
                    rules="ip_or_domain"
                    :has-default-save-callback="false"
                    :read-only="!canEditCameras"
                    @save="updateNvrConfig"
                  />

                  <!-- NVR Username -->
                  <SummaryElement
                    v-model="nvrUsername"
                    :has-default-save-callback="false"
                    :read-only="!canEditCameras"
                    @save="updateNvrConfig"
                  />

                  <!-- NVR Password -->
                  <SummaryElement
                    v-model="nvrPassword"
                    is-password
                    is-copyable
                    :has-default-save-callback="false"
                    :read-only="!canEditCameras"
                    @save="updateNvrConfig"
                  />

                  <!-- NVR Ports -->
                  <ERow no-gutters>
                    <div class="d-flex justify-start">
                      <div
                        class="summary-element__label subtitle-2 mr-2 pl-1 pt-1"
                      >
                        Ports
                      </div>
                      <div
                        :class="{
                          'd-flex':
                            $vuetify.breakpoint.xlOnly ||
                            $vuetify.breakpoint.smAndDown,
                        }"
                      >
                        <!-- NVR HTTP Port -->
                        <CameraSummaryPortElement
                          v-model="nvrHttpPort"
                          :has-default-save-callback="false"
                          :port="camera.nvrHttpPort"
                          :host="camera.nvrHost"
                          :scheme="camera.nvrScheme"
                          class="ml-n3"
                          dense
                          :read-only="!canEditCameras"
                          @save="updateNvrConfig"
                          @nvr-http-port-check-result="
                            updateExNvrHttpPortStatus
                          "
                        />

                        <!-- NVR RTSP Port -->
                        <CameraSummaryPortElement
                          v-model="nvrRtspPort"
                          :has-default-save-callback="false"
                          :port="camera.nvrRtspPort"
                          :host="camera.nvrHost"
                          :scheme="camera.nvrScheme"
                          class="ml-n3"
                          dense
                          :read-only="!canEditCameras"
                          @save="updateNvrConfig"
                        />
                      </div>
                    </div>
                  </ERow>

                  <!-- HDD Capacity -->
                  <SummaryElement label="HDD Capacity" read-only align="start">
                    {{ hddCapacity }}
                  </SummaryElement>

                  <!-- HDD Free Space -->
                  <SummaryElement
                    label="HDD Free Space"
                    read-only
                    align="start"
                  >
                    <div class="d-flex justify-start align-center">
                      <div>{{ hddFreeSpace }}</div>
                      <div>
                        <v-tooltip bottom>
                          <template #activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              color="primary"
                              rounded
                              icon
                              small
                              v-on="on"
                              @click="refreshDeviceSettings"
                            >
                              <v-icon x-small :class="spinRefresh"
                                >fa fa-refresh</v-icon
                              >
                            </v-btn>
                          </template>
                          <span> Refresh device settings </span>
                        </v-tooltip>
                      </div>
                    </div>
                  </SummaryElement>

                  <!-- HDD Status -->
                  <SummaryElement label="HDD Status" read-only align="start">
                    {{ hddStatus }}
                  </SummaryElement>

                  <!-- Firmware -->
                  <SummaryElement label="Firmware" read-only align="start">
                    {{ camera.cameraFirmwareVersion }}
                  </SummaryElement>

                  <!-- Resolution -->
                  <SummaryElement
                    v-if="nvrModel.value === 'hikvision'"
                    label="Resolution"
                    read-only
                    align="start"
                  >
                    {{ camera.resolution }}
                  </SummaryElement>

                  <!-- Timezone -->
                  <SummaryElement
                    v-if="nvrModel.value === 'hikvision'"
                    label="Device Timezone"
                    read-only
                    align="start"
                  >
                    {{ getDeviceTimezone(camera.cameraTimezone) }}
                  </SummaryElement>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-card>
          </ECol>

          <!-- ROUTER DETAILS -->
          <ECol
            v-if="!cameraDialogStore.isCleanMode"
            :class="{ 'w-100': $vuetify.breakpoint.xs }"
            cols="auto"
            xs="12"
            sm="6"
            md="6"
            lg="6"
            xl="6"
          >
            <v-card outlined class="d-flex h-100" rounded="0" elevation="0">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div class="d-flex align-center">
                    <span class="subtitle-2"
                      >Router
                      <v-btn
                        v-if="!isFetchingRouter && canEditCameras"
                        small
                        icon
                        class="mr-2"
                        @click.stop="routerDialog = true"
                      >
                        <v-icon v-if="router.id">fas fa-pen</v-icon>
                        <v-icon v-else>fa-plus</v-icon>
                      </v-btn>
                    </span>
                    <v-tooltip top>
                      <template #activator="{ on, attrs }">
                        <v-img
                          :src="`${require('~/assets/img/router.png')}`"
                          class="ml-auto"
                          width="24px"
                          maxWidth="24px"
                          height="100%"
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <span>
                        <v-img
                          :src="`${require('~/assets/img/router.png')}`"
                          class="h-100 w-100"
                        />
                      </span>
                    </v-tooltip>
                  </div>

                  <RouterDialog
                    v-model="routerDialog"
                    :edited-router="router"
                    inline
                    @change="onRouterChange"
                    @router-created="onRouterChange"
                  />
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <CameraSummaryRouter
                    v-if="!isFetchingRouter"
                    :router="router"
                    :camera="camera"
                    :read-only="!canEditCameras"
                    @router-change="onRouterChange"
                    @refetch-router-details="fetchRouter"
                  />
                  <div
                    v-else
                    class="h-80 w-100 d-flex justify-center align-center"
                  >
                    <EvercamLoadingAnimation size="TwoXl" />
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-card>
          </ECol>

          <!-- KIT DETAILS -->
          <ECol
            v-if="!cameraDialogStore.isCleanMode"
            :class="{ 'w-100': $vuetify.breakpoint.xs }"
            cols="auto"
            xs="12"
            sm="6"
            md="6"
            lg="6"
            xl="6"
          >
            <v-card outlined class="d-flex h-100" rounded="0" elevation="0">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div class="d-flex align-center">
                    <span class="subtitle-2">Kit </span>
                    <v-tooltip top>
                      <template #activator="{ on, attrs }">
                        <v-img
                          :src="
                            require(`~/assets/img/${
                              router.powerType === 'mains' ? 'main' : 'solar'
                            }.jpg`)
                          "
                          class="ml-auto"
                          width="24px"
                          maxWidth="24px"
                          height="100%"
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <span>
                        <v-img
                          :src="
                            require(`~/assets/img/${
                              router.powerType === 'mains' ? 'main' : 'solar'
                            }.jpg`)
                          "
                          class="h-100 w-100"
                        />
                      </span>
                    </v-tooltip>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <!-- Kit Serial -->
                  <SummaryElement read-only label="Serial">
                    <component
                      :is="isKitSummaryPage ? 'div' : 'nuxt-link'"
                      v-if="kit"
                      :to="`/kits/${kit?.id}`"
                    >
                      <div
                        class="d-flex align-center primary--text cursor-pointer"
                        @click="onKitClicked"
                      >
                        {{ kit.serial }}
                      </div>
                    </component>
                    <span v-else> - </span>
                  </SummaryElement>

                  <!-- Kit Name -->
                  <SummaryElement read-only label="Name">
                    <div class="d-flex align-center">
                      {{ kit?.name || "-" }}
                    </div>
                  </SummaryElement>

                  <!-- Kit Status -->
                  <SummaryElement read-only label="Status">
                    <KitStatusChip v-if="kit?.status" :status="kit?.status" />
                    <span v-else>-</span>
                  </SummaryElement>

                  <SummaryElement
                    :value="kit?.createdAt"
                    read-only
                    label="Created At"
                  />
                  <!-- Timezone -->
                  <SummaryElement v-model="timezone" read-only />
                  <CameraSummaryOwner
                    :camera="camera"
                    :read-only="!canEditCameras"
                  />
                  <SummaryElement
                    v-model="isPublic"
                    :read-only="!canEditCameras"
                  />
                  <SummaryElement label="Description" read-only align="start">
                    <pre :style="{ 'white-space': 'pre-wrap' }">{{
                      camera.description || "-"
                    }}</pre>
                  </SummaryElement>
                  <ScheduleSummaryElement
                    v-model="isPowerScheduleActive"
                    :has-default-save-callback="false"
                    :read-only="!canEditCameras"
                    :schedule-config="powerSchedule"
                    @update-schedule="updatePowerSchedule"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-card>
          </ECol>

          <!-- CAMERA URLS -->
          <ECol col="12">
            <v-card
              outlined
              class="d-flex h-100 justify-center align-center"
              rounded="0"
              elevation="0"
            >
              <div class="d-flex e-flex-wrap w-100">
                <div class="e-inline-flex px-0">
                  <!-- Snapshot URL -->
                  <SummaryElement
                    v-model="snapshotUrl"
                    :has-default-save-callback="false"
                    :custom-read-only-display="!isOtherModelCamera"
                    class="pb-0"
                    @save="changeCameraUrl"
                  >
                    <template #value-text>
                      <a target="_blank" :href="cameraUrl" @click.stop>
                        <v-tooltip top>
                          <template #activator="{ on }">
                            <ETruncatedDiv
                              :width="$vuetify.breakpoint.smOnly ? 200 : 270"
                              v-on="on"
                            >
                              {{ getShortUrl(snapshotUrl.value) }}
                            </ETruncatedDiv>
                          </template>
                          <span>{{ getShortUrl(snapshotUrl.value) }}</span>
                        </v-tooltip>
                      </a>
                    </template>
                    <template
                      #append
                      class="pl-2 text-truncate subtitle-2 font-weight-regular"
                    >
                      <div
                        v-if="snapshotUrl.value"
                        class="d-flex align-center"
                        @click.stop="() => {}"
                      >
                        <div class="pl-2">
                          <OpenInNewWindowButton
                            :target-link="cameraUrl"
                            hint="Open in a new tab"
                          />
                          <CopyToClipboardBtn
                            :text="cameraUrl"
                            icon
                            color="primary"
                          />
                        </div>
                      </div>
                      <span v-else> - </span>
                    </template>
                  </SummaryElement>
                </div>
                <div class="e-inline-flex px-0">
                  <!-- RTSP URL -->
                  <SummaryElement
                    v-model="rtspUrl"
                    :has-default-save-callback="false"
                    :custom-read-only-display="!isOtherModelCamera"
                    class="pt-0"
                    @save="changeCameraUrl"
                  >
                    <template #value-text>
                      <a target="_blank" :href="rtspUrl.value" @click.stop>
                        <v-tooltip top>
                          <template #activator="{ on }">
                            <ETruncatedDiv
                              :width="$vuetify.breakpoint.smOnly ? 200 : 270"
                              v-on="on"
                            >
                              {{ getShortUrl(rtspUrl.value) }}
                            </ETruncatedDiv>
                          </template>
                          <span>{{ getShortUrl(rtspUrl.value) }}</span>
                        </v-tooltip>
                      </a>
                    </template>
                    <template
                      #append
                      class="pl-2 text-truncate subtitle-2 font-weight-regular"
                    >
                      <div
                        v-if="rtspUrl.value"
                        class="d-flex align-center"
                        @click.stop="() => {}"
                      >
                        <div class="pl-2">
                          <OpenInNewWindowButton
                            :target-link="rtspUrl.value"
                            hint="Open in a new tab"
                          />
                          <CopyToClipboardBtn
                            :text="rtspUrl.value"
                            icon
                            color="primary"
                          />
                        </div>
                      </div>
                      <span v-else> - </span>
                    </template>
                  </SummaryElement>
                </div>
              </div>
            </v-card>
          </ECol>
        </ERow>
      </v-expansion-panels>
    </ECol>

    <!-- CAMERA VIEW DETAILS -->
    <ECol cols="auto" md="6" xs="12" class="d-flex h-100">
      <v-expansion-panels
        v-model="cameraViewPanel"
        class="h-100"
        flat
        accordion
        multiple
      >
        <v-card outlined class="d-flex h-100 w-100" rounded="0" elevation="0">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span class="subtitle-2">Camera View</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <ERow no-gutters>
                <ECol
                  :class="{ 'w-100': $vuetify.breakpoint.xs }"
                  cols="auto"
                  xs="12"
                  sm="6"
                  md="6"
                  lg="6"
                  xl="6"
                >
                  <!-- Status -->
                  <SummaryElement
                    v-model="status"
                    :class="statusClass"
                    :read-only="!canEditCameras"
                  />

                  <!-- Snapshot from Camera vs NVR -->
                  <SummaryElement read-only label="Snapshot">
                    <v-radio-group
                      v-model="recordingFromNvr"
                      row
                      dense
                      class="mt-0 py-1 ml-n2"
                      hide-details
                      :disabled="
                        !camera.nvrHttpPort ||
                        isSwitchingSnapshotSource ||
                        !canEditCameras
                      "
                    >
                      <v-radio :value="false">
                        <template #label>
                          <div class="subtitle-2 text-no-wrap">Camera</div>
                        </template>
                      </v-radio>

                      <v-radio :value="true">
                        <template #label>
                          <div class="subtitle-2 text-no-wrap">NVR</div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </SummaryElement>
                  <div class="caption set-top-border pa-1 font-weight-medium">
                    Cloud recordings
                  </div>
                  <CameraSummaryCloudRecordings
                    :disabled="cloudRecordingsDisabled || !canEditCameras"
                    class="summary-container w-100"
                  />

                  <div
                    class="d-flex set-top-border justify-between align-center"
                  >
                    <div class="caption pa-1 font-weight-medium">
                      Camera features
                    </div>
                    <EditingButton
                      v-if="canEditCameras"
                      :can-edit="canEditFeatureFlags"
                      :is-saving="isUpdatingFeatureFlags"
                      @start-editing="onStartEditingFeatureFlags"
                      @save="onSaveFeatureFlags"
                      @cancel="onCancelEditingFeatureFlags"
                    />
                  </div>
                  <FeatureFlagsTree
                    ref="featureFlagTree"
                    v-model="selectedFeatureFlags"
                    :items="featureFlags"
                    :readonly="isUpdatingFeatureFlags || !canEditFeatureFlags"
                  />
                  <!-- ANPR Sources -->
                  <template v-if="isAnpr">
                    <div class="caption set-top-border pa-1 font-weight-medium">
                      ANPR Sources
                    </div>
                    <SummaryElement read-only align="start">
                      <v-checkbox
                        :input-value="anprSources.includes('camera')"
                        label="Camera"
                        hide-details
                        dense
                        @change="toggleAnprSource('camera')"
                      ></v-checkbox>
                      <v-checkbox
                        label="NVR"
                        :input-value="anprSources.includes('nvr')"
                        hide-details
                        dense
                        @change="toggleAnprSource('nvr')"
                      ></v-checkbox>
                    </SummaryElement>
                  </template>
                </ECol>
                <ECol
                  :class="{ 'w-100': $vuetify.breakpoint.xs }"
                  cols="auto"
                  xs="12"
                  sm="6"
                  md="6"
                  lg="6"
                  xl="6"
                >
                  <CameraSummaryMap :read-only="!canEditCameras" />
                </ECol>
              </ERow>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-card>
      </v-expansion-panels>
    </ECol>
  </ERow>
</template>

<script lang="ts">
import Vue from "vue"
import SummaryElement from "@/components/cameraTabs/summary/SummaryElement"
import ScheduleSummaryElement from "@/components/cameraTabs/summary/ScheduleSummaryElement"
import CameraSummaryPortElement from "@/components/cameraTabs/summary/CameraSummaryPortElement"
import CameraSummaryCloudRecordings from "@/components/cameraTabs/summary/CameraSummaryCloudRecordings"
import timezones from "@evercam/shared/constants/timezones"
import CopyToClipboardBtn from "@evercam/shared/components/CopyToClipboardBtn"
import CameraSummaryRouter from "@/components/cameraTabs/summary/CameraSummaryRouter"
import RouterDialog from "@/components/routers/RouterDialog"
import CameraSummaryOwner from "@/components/cameraTabs/summary/CameraSummaryOwner"
import CameraSummaryProject from "@/components/cameraTabs/summary/CameraSummaryProject"
import CameraSummaryNvrDeviceId from "@/components/cameraTabs/summary/CameraSummaryNvrDeviceId"
import OpenInNewWindowButton from "@/components/OpenInNewWindowButton"
import FeatureFlagsTree from "@/components/FeatureFlagsTree"
import vendorModels from "@evercam/shared/constants/vendorModels"
import { mapStores } from "pinia"
import { useCameraDialogStore } from "@/stores/cameraDialog"
import CameraSummaryMap from "@/components/cameraTabs/summary/CameraSummaryMap"
import CameraUtils from "@/mixins/cameraUtils"
import {
  CameraStatuses,
  CameraFeatureFlags,
  BooleanOptions,
} from "@/components/constants"
import { CameraStatus, CameraFeatureFlag } from "@evercam/shared/types/camera"
import { Router } from "@evercam/shared/types/router"
import { AdminApi } from "@evercam/shared/api/adminApi"
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"
import KitStatusChip from "@/components/kits/KitStatusChip.vue"
import EditingButton from "@/components/EditingButton"
import { PERMISSIONS } from "@/constants/permissions"
import { Kit } from "@evercam/shared/types"
import { useKitSummaryStore } from "@/stores/kitSummary"
import { formatUrl } from "@evercam/shared/utils"

export default Vue.extend({
  components: {
    ScheduleSummaryElement,
    KitStatusChip,
    SummaryElement,
    CameraSummaryPortElement,
    CameraSummaryCloudRecordings,
    CopyToClipboardBtn,
    CameraSummaryRouter,
    RouterDialog,
    CameraSummaryOwner,
    CameraSummaryProject,
    CameraSummaryNvrDeviceId,
    OpenInNewWindowButton,
    FeatureFlagsTree,
    EvercamLoadingAnimation,
    EditingButton,
    CameraSummaryMap,
  },
  mixins: [CameraUtils],
  props: {
    camera: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      name: { key: "name", label: "Name", value: "", type: "TEXT_FIELD" },
      panel: [0, 1, 2, 3, 4],
      cameraViewPanel: [0],
      description: {
        key: "description",
        label: "Description",
        value: "",
        type: "TEXT_FIELD",
      },
      cameraHost: {
        key: "host",
        label: "Host",
        value: "",
        type: "TEXT_FIELD",
        isCameraConfig: true,
      },
      cameraHttpPort: {
        key: "httpPort",
        label: "HTTP",
        value: "",
        type: "TEXT_FIELD",
        isCameraConfig: true,
      },
      nvrHttpPort: {
        key: "httpPort",
        label: "HTTP",
        value: "",
        type: "TEXT_FIELD",
      },
      cameraRtspPort: {
        key: "rtspPort",
        label: "RTSP",
        value: "",
        type: "TEXT_FIELD",
        isCameraConfig: true,
      },
      nvrRtspPort: {
        key: "rtspPort",
        label: "RTSP",
        value: "",
        type: "TEXT_FIELD",
        isNvrConfig: true,
      },
      created: {
        key: "created",
        label: "Created",
        value: "",
        type: "TEXT_FIELD",
      },
      status: {
        key: "status",
        label: "Status",
        value: "",
        type: "SINGLE_SELECT",
        dataset: [],
      },
      timezone: {
        key: "timezone",
        label: "Timezone",
        value: "",
        type: "SINGLE_SELECT",
        dataset: timezones,
      },
      zoho: {
        key: "zoho",
        label: "Zoho",
      },
      cameraModel: {
        key: "model",
        label: "Model",
        value: "",
        type: "SINGLE_SELECT",
        dataset: vendorModels.CAMERAMODELS,
        isCameraConfig: true,
      },
      nvrModel: {
        key: "model",
        label: "Model",
        value: "",
        type: "SINGLE_SELECT",
        dataset: vendorModels.NVRMODELS,
      },
      deviceId: {
        key: "deviceId",
        label: "Device ID",
        value: "",
        type: "TEXT_FIELD",
      },
      nvrHost: {
        key: "nvrHost",
        label: "Host",
        value: "",
        type: "TEXT_FIELD",
      },
      nvrUsername: {
        key: "nvrUsername",
        label: "Username",
        value: "",
        type: "TEXT_FIELD",
      },
      nvrPassword: {
        key: "nvrPassword",
        label: "Password",
        value: "",
        type: "TEXT_FIELD",
      },
      cameraUsername: {
        key: "username",
        label: "Username",
        value: "",
        type: "TEXT_FIELD",
        isCameraConfig: true,
      },
      cameraPassword: {
        key: "password",
        label: "Password",
        value: "",
        type: "TEXT_FIELD",
        isCameraConfig: true,
      },
      snapshotUrl: {
        key: "pictureEndpoint",
        label: "Snapshot Url",
        value: "",
        type: "TEXT_FIELD",
        isCameraConfig: true,
      },
      rtspUrl: {
        key: "h264Url",
        label: "Stream Url",
        value: "",
        type: "TEXT_FIELD",
      },
      isPublic: {
        key: "isPublic",
        label: "Public",
        value: "",
        type: "SINGLE_SELECT",
        dataset: [
          { name: "True", value: true },
          { name: "False", value: false },
        ],
      },
      isPowerScheduleActive: {
        key: "isPowerScheduleActive",
        label: "Power Schedule",
        value: false,
        type: "SINGLE_SELECT",
        dataset: BooleanOptions,
      },
      cameraLocation: {
        key: "coordinates",
        label: "Lat, Lng",
        value: "",
        type: "TEXT_FIELD",
        isCameraLocation: true,
      },
      cameraHeading: {
        key: "heading",
        label: "Camera Angle",
        value: "",
        type: "TEXT_FIELD",
      },
      sim: null,
      billPayer: null,
      recordingFromNvr: null,
      router: {} as Router,
      isFetchingRouter: false,
      routerDialog: false,
      isSwitchingSnapshotSource: false,
      featureFlag: Object.values(CameraFeatureFlag),
      selectedFeatureFlags: [],
      oldSelectedFeatureFlags: [],
      isUpdatingFeatureFlags: false,
      spinRefresh: "",
      hddInfo: [],
      nvrModelOldValue: vendorModels.NVRMODELS.find(
        (m) => m.value === this.camera.nvrModel
      )?.value,
      nvrDeviceIdOldValue: this.camera.nvrDeviceId,
      canEditFeatureFlags: false,
      featureFlags: CameraFeatureFlags,
      anprSources: [],
    }
  },
  computed: {
    ...mapStores(useCameraDialogStore, useKitSummaryStore),
    kit(): Kit | null {
      if (
        !this.camera.kitId ||
        this.kitSummaryStore.kit?.id !== this.camera.kitId
      ) {
        return null
      }

      return this.kitSummaryStore.kit
    },
    powerSchedule() {
      return this.kit ? this.kit.powerSchedule : this.camera.powerSchedule
    },
    isKitSummaryPage(): boolean {
      return this.$route.path.includes("kits")
    },
    cloudRecordingsDisabled(): boolean {
      return [CameraStatus.Decommissioned, CameraStatus.OnHold].includes(
        this.camera.status
      )
    },
    cameraUrl() {
      const scheme = this.recordingFromNvr
        ? this.camera.nvrScheme
        : this.camera.cameraScheme
      const port = this.recordingFromNvr
        ? this.camera.nvrHttpPort
        : this.camera.cameraHttpPort

      const host = `${this.camera.cameraUsername}:${this.encodedPassword}@${this.camera.cameraHost}`

      return formatUrl({
        scheme,
        port,
        host,
        path: this.snapshotUrl.value,
      })
    },
    nvrSnapshotUrl() {
      return `${this.camera.nvrPictureEndpoint}`
    },
    statusClass(): string {
      return this.getStatusColor(this.camera.status)
    },
    isOtherModelCamera() {
      return this.cameraModel.value === "other"
    },
    encodedPassword(): string {
      return encodeURIComponent(this.camera?.cameraPassword)
    },
    hddCapacity() {
      if (Array.isArray(this.hddInfo)) {
        return this.hddInfo?.map((hddInfo) => hddInfo?.capacity)?.join("/")
      }

      return this.hddInfo?.capacity
    },
    hddStatus() {
      if (Array.isArray(this.hddInfo)) {
        return this.hddInfo?.map((hddInfo) => hddInfo?.status)?.join("/")
      }

      return this.hddInfo?.status
    },
    hddFreeSpace() {
      if (Array.isArray(this.hddInfo)) {
        return this.hddInfo?.map((hddInfo) => hddInfo?.freeSpace)?.join("/")
      }

      return this.hddInfo?.freeSpace
    },
    canEditCameras() {
      return this.$permissions.user.can(PERMISSIONS.CAMERAS.UPDATE)
    },
    isAnpr() {
      return this.camera?.featureFlags?.includes(CameraFeatureFlag.ANPR)
    },
  },
  watch: {
    camera: {
      immediate: true,
      deep: true,
      async handler() {
        this.name.value = this.camera.name
        this.description.value = this.camera.description
        this.status.dataset = CameraStatuses.map((status) => {
          return {
            ...status,
            class: this.getStatusColor(status.value),
          }
        })
        this.status.value = this.status.dataset.find(
          (el) => el.value === this.camera.status
        )?.value
        this.cameraHost.value = this.camera.cameraHost
        this.timezone.value = this.camera.timezone
        this.created.value = this.getFormattedTime(this.camera.createdAt)
        this.cameraHttpPort.value = this.camera.cameraHttpPort
        this.nvrHttpPort.value = this.camera.nvrHttpPort
        this.cameraRtspPort.value = this.camera.cameraRtspPort
        this.nvrRtspPort.value = this.camera.nvrRtspPort
        this.cameraModel.value = this.camera.cameraModel
        this.nvrModel.value = this.camera.nvrModel
        this.deviceId.value = this.camera.nvrDeviceId
        this.nvrHost.value = this.camera.nvrHost
        this.nvrUsername.value = this.camera.nvrUsername
        this.nvrPassword.value = this.camera.nvrPassword
        this.cameraUsername.value = this.camera.cameraUsername
        this.cameraPassword.value = this.camera.cameraPassword
        this.cameraLocation.value = `${this.camera.location.lat}, ${this.camera.location.lng}`
        this.cameraHeading.value = this.camera.heading
        this.recordingFromNvr = this.camera.recordingFromNvr
        this.isPublic.value = this.camera.isPublic
        this.billPayer = this.camera.billPayer
        this.sim = this.camera.simNumber
        this.hddInfo = this.camera.hddInfo
        this.selectedFeatureFlags = this.camera?.featureFlags || []
        this.anprSources = this.camera?.anprSources
        this.updateOfflineReason()
        this.fetchRouter()
        this.updateCameraUrls()
        this.maybeFetchKit()
      },
    },
    powerSchedule: {
      handler(powerSchedule) {
        this.isPowerScheduleActive.value = this.kit
          ? !!Object.keys(this.kit.powerSchedule || {}).length
          : this.camera.isPowerScheduleActive

        if (!powerSchedule) {
          this.updatePowerSchedule({})
        }
      },
      immediate: true,
      deep: true,
    },
    async recordingFromNvr(val, oldVal) {
      if (oldVal === null) {
        return
      }
      this.isSwitchingSnapshotSource = true
      await this.cameraDialogStore.updateCamera({
        recordingFromNvr: val,
      })
      this.isSwitchingSnapshotSource = false
    },
    router(newRouter = {}, oldRouter = {}) {
      if (newRouter.id !== oldRouter.id) {
        this.fetchRouter()
      }
    },
    "cameraModel.value": "changeCameraUrl",
    nvrModel(_newValue, oldValue) {
      if (oldValue) {
        this.nvrModelOldValue = oldValue.value
      }
    },
    nvrDeviceId(_newValue, oldValue) {
      if (oldValue) {
        this.nvrDeviceIdOldValue = oldValue.value
      }
    },
  },
  mounted() {
    this.fetchRouter()
  },
  beforeDestroy() {
    this.cameraDialogStore.isCheckingNvrHttpPortStatus = true
    this.cameraDialogStore.isNvrHttpPortOpen = false
    this.cameraDialogStore.exNvrToken = ""
  },
  methods: {
    updateExNvrHttpPortStatus(status) {
      this.cameraDialogStore.isCheckingNvrHttpPortStatus = false
      this.cameraDialogStore.isNvrHttpPortOpen = status
    },
    async changeCameraUrl() {
      if (this.isOtherModelCamera) {
        await this.cameraDialogStore.updateCamera({
          cameraConfig: {
            model: this.cameraModel.value,
            pictureEndpoint: this.snapshotUrl.value,
            streamEndpoint: this.camera.streamEndpoint,
          },
        })
      }
    },
    getFormattedTime(time) {
      return this.$moment
        .tz(time, this.camera.timezone)
        .utc()
        .format("DD MMM YYYY HH:mm:ss")
    },
    updateCameraUrls() {
      // snapshotUrl value
      if (
        this.recordingFromNvr &&
        this.camera.nvrPictureEndpoint &&
        this.camera.nvrHttpPort
      ) {
        this.snapshotUrl.value = this.nvrSnapshotUrl
      } else if (
        this.camera.cameraPictureEndpoint &&
        this.camera.cameraHttpPort
      ) {
        this.snapshotUrl.value = this.camera.cameraPictureEndpoint
      } else {
        this.snapshotUrl.value = ""
      }

      // rtspUrl value
      if (this.isOtherModelCamera) {
        this.rtspUrl.value = this.camera.streamEndpoint
      } else if (!this.camera.cameraHost || !this.camera.streamEndpoint) {
        this.rtspUrl.value = ""
      } else {
        const rtspPort = this.camera.nvrRtspPort
          ? this.camera.nvrRtspPort
          : this.camera.cameraRtspPort
        this.rtspUrl.value = `rtsp://${this.camera.cameraUsername}:${this.encodedPassword}@${this.camera.cameraHost}:${rtspPort}${this.camera.streamEndpoint}`
      }
    },
    getShortUrl(value) {
      let url
      if (value) {
        url = new RegExp(/[0-9a-zA-Z](\/[0-9a-zA-Z].*)/g).exec(value)

        return url ? url[1] : value
      } else {
        return value
      }
    },
    async fetchRouter() {
      this.isFetchingRouter = true
      try {
        const routerId = this.camera.routerId
        if (routerId) {
          const router = (await AdminApi.routers.getRouter(routerId)) || {}

          this.router = {
            ...router,
            externalHost: this.camera.cameraHost,
          }
        } else {
          this.router = {
            kitId: this.camera.kitId,
            cameras: [
              {
                name: this.camera.name,
                id: this.camera.id,
                exid: this.camera.exid,
              },
            ],
          }
        }
      } catch (error) {
        this.$notifications.error({
          text: "Could not fetch the router",
          error,
        })
      } finally {
        this.isFetchingRouter = false
      }
    },
    onRouterChange(change) {
      this.router = {
        ...this.router,
        ...change,
      }

      if (this.router.id !== this.camera.routerId) {
        this.cameraDialogStore.updateCamera({
          routerId: this.router.id,
        })
      }
      this.fetchRouter()
    },
    async updatePowerSchedule(powerSchedule) {
      if (this.kitSummaryStore.kit?.id) {
        await this.kitSummaryStore.updateKit({ powerSchedule })
      } else {
        await this.cameraDialogStore.updateCamera({
          isPowerScheduleActive: this.isPowerScheduleActive.value,
          powerSchedule,
        })
      }
    },
    getDeviceTimezone(timezone) {
      return timezone && timezone.indexOf("DST") != -1
        ? timezone.substr(0, timezone.indexOf("DST"))
        : timezone
    },
    async refreshDeviceSettings() {
      try {
        this.spinRefresh = "fa-spin"
        const resp = await AdminApi.cameras.refreshDeviceSettings(
          this.camera.exid
        )
        this.hddInfo = resp.hddInfo
      } catch (error) {
        this.$notifications.error({
          text: "Could not refresh the device settings",
          error,
        })
      } finally {
        this.spinRefresh = ""
      }
    },
    async onSaveFeatureFlags() {
      this.isUpdatingFeatureFlags = true
      await this.cameraDialogStore.updateCamera({
        featureFlags: this.selectedFeatureFlags,
      })
      this.canEditFeatureFlags = false
      this.isUpdatingFeatureFlags = false
      this.oldSelectedFeatureFlags = []
    },
    onStartEditingFeatureFlags() {
      this.oldSelectedFeatureFlags = [...this.selectedFeatureFlags]
      this.canEditFeatureFlags = true
    },
    onCancelEditingFeatureFlags() {
      this.selectedFeatureFlags = [...this.oldSelectedFeatureFlags]
      this.canEditFeatureFlags = false
    },
    updateNvrConfig() {
      if (
        this.nvrModel.value === this.camera.nvrModel &&
        this.deviceId.value === this.camera.nvrDeviceId &&
        this.nvrHttpPort.value === this.camera.nvrHttpPort &&
        this.nvrRtspPort.value === this.camera.nvrRtspPort &&
        this.nvrHost.value === this.camera.nvrHost &&
        this.nvrUsername.value === this.camera.nvrUsername &&
        this.nvrPassword.value === this.camera.nvrPassword
      ) {
        return
      }

      if (this.nvrModel.value === "hikvision" && isNaN(this.deviceId.value)) {
        this.$notifications.warn(
          "The NVR Device ID needs must be a number for the Hikvision NVR model"
        )
        if (this.nvrModelOldValue !== "hikvision") {
          this.nvrModel = {
            ...this.nvrModel,
            value: this.nvrModelOldValue,
          }
        } else {
          this.deviceId = {
            ...this.deviceId,
            value: this.nvrDeviceIdOldValue,
          }
        }

        return
      }

      if (
        (this.nvrModel.value &&
          this.nvrHttpPort.value &&
          this.nvrHost.value &&
          this.nvrUsername.value &&
          this.nvrPassword.value) ||
        this.nvrRtspPort.value
      ) {
        let scheme = "http"
        if (["nxwitness", "ex_nvr"].includes(this.nvrModel.value)) {
          scheme = "https"
        }

        const params = {
          deviceId: this.deviceId.value,
          nvr: {
            model: this.nvrModel.value,
            username: this.nvrUsername.value,
            password: this.nvrPassword.value,
            httpUrl: `${scheme}://${this.nvrHost.value}:${this.nvrHttpPort.value}`,
            rtspUrl: this.nvrRtspPort.value
              ? `rtsp://${this.nvrHost.value}:${this.nvrRtspPort.value}`
              : null,
          },
        }
        this.cameraDialogStore.updateCamera(params)
      }
    },
    updateOfflineReason() {
      if (
        this.camera.status !== CameraStatus.Offline ||
        !this.camera.offlineReason
      ) {
        return
      }
      this.$set(
        this.status,
        "dataset",
        this.status.dataset.map((s) => {
          if (s.value === CameraStatus.Offline) {
            return {
              ...s,
              name: `${s.name} (Reason: ${this.camera.offlineReason})`,
            }
          } else {
            return s
          }
        })
      )
    },
    async maybeFetchKit() {
      if (
        this.camera.kitId &&
        this.kitSummaryStore.kit?.id !== this.camera.kitId
      ) {
        await this.kitSummaryStore.loadKit(this.camera.kitId)
      }
    },
    onKitClicked() {
      if (this.isKitSummaryPage) {
        this.$emit("close")
      }
    },
    toggleAnprSource(source) {
      if (this.anprSources.includes(source)) {
        this.anprSources = this.anprSources.filter((s) => s !== source)
      } else {
        this.anprSources.push(source)
      }
      this.cameraDialogStore.updateCamera({
        anprSources: this.anprSources,
      })
    },
  },
})
</script>

<style>
.v-expansion-panels .v-expansion-panel-header__icon i {
  font-size: 20px !important;
}

.set-top-border {
  border-top: 1px solid lightgrey;
}

.v-expansion-panel-header {
  padding: 0 8px !important;
  height: 30px !important;
}

.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 30px !important;
}

.v-expansion-panel-content__wrap {
  padding: 0 4px !important;
}
</style>

<style lang="scss" scoped>
.summary--disabled {
  background: #ddd6;
}

.v-chip {
  font-size: 1em;
}
</style>
