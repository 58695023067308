<template>
  <SummaryElement
    v-bind="$attrs"
    :read-only="readOnly"
    rules="numeric"
    class="summary-port-element"
    :class="{
      'summary-port-element--open': !isCheckingPortStatus && isPortOpen,
      'summary-port-element--closed': !isCheckingPortStatus && !isPortOpen,
    }"
    v-on="$listeners"
  >
    <slot></slot>
    <template v-if="host && port" #append>
      <v-tooltip bottom content-class="d-inline-block">
        <template #activator="{ on, attrs }">
          <v-btn
            icon
            color="primary"
            x-small
            v-bind="attrs"
            target="_blank"
            class="ml-1 pb-05"
            :href="formattedUrl"
            v-on="on"
            @click.stop="() => {}"
          >
            <v-icon small> fa-external-link-alt </v-icon>
          </v-btn>
        </template>
        <span>Open In A New Window </span>
      </v-tooltip>
      <v-btn
        v-if="![undefined, false].includes($attrs.dense) && !readOnly"
        small
        icon
      >
        <v-icon class="edit-icon">fas fa-pen</v-icon>
      </v-btn>
    </template>
  </SummaryElement>
</template>

<script>
import SummaryElement from "@/components/cameraTabs/summary/SummaryElement"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { formatUrl } from "@evercam/shared/utils"

export default {
  name: "CameraSummaryPortElement",
  components: {
    SummaryElement,
  },
  props: {
    port: {
      type: [String, Number],
      default: "",
    },
    host: {
      type: String,
      default: "",
    },
    scheme: {
      type: String,
      default: "http",
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isCheckingPortStatus: false,
      isPortOpen: false,
    }
  },
  computed: {
    formattedUrl() {
      return formatUrl({
        port: this.port,
        scheme: this.scheme,
        host: this.host,
      })
    },
  },
  watch: {
    port: {
      immediate: true,
      handler: "checkPortStatus",
    },
    host: {
      immediate: true,
      handler: "checkPortStatus",
    },
  },
  methods: {
    async checkPortStatus() {
      if (this.isCheckingPortStatus) {
        return
      }
      this.isCheckingPortStatus = true
      try {
        if (!this.port || !this.host) {
          return
        }
        const { open } = await EvercamApi.cameras.portCheck({
          params: {
            address: this.host,
            port: this.port,
          },
        })
        this.isPortOpen = open
        this.$emit("nvr-http-port-check-result", this.isPortOpen)
      } catch (error) {
        this.$notifications.error({
          text: "Could not check port status!",
          error,
        })
      } finally {
        this.isCheckingPortStatus = false
      }
    },
  },
}
</script>

<style lang="scss">
.summary-port-element {
  .v-input input {
    width: 3.9rem !important;
    padding-top: 3px !important;
  }
  .v-icon {
    padding-top: 2px !important;
  }
  .v-icon.edit-icon {
    font-size: 11px !important;
    opacity: 0;
  }
  &:hover {
    .v-icon.edit-icon {
      opacity: 1;
    }
  }
  &--open {
    color: #2e8f45 !important;
  }
  &--closed {
    color: #cf2233 !important;
  }
}
</style>
