<template>
  <div>
    <!-- Router Model -->
    <SummaryElement
      read-only
      label="Model"
      :value="router.routerType"
      is-copyable
    />
    <SummaryElement read-only label="SIMs" align="start">
      <div v-for="(simInfo, i) in sims" :key="i" class="d-flex align-center">
        <div
          class="primary--text cursor-pointer"
          @click="inspectSim(simInfo.number)"
        >
          {{ simInfo.number }}
        </div>
        <CopyToClipboardBtn :text="simInfo.number" icon color="primary" />
        <v-tooltip bottom>
          <template #activator="{ on }">
            <span v-on="on">
              <v-btn icon color="primary" x-small @click="editSim(simInfo)">
                <v-icon small> fas fa-pen </v-icon>
              </v-btn>
            </span>
          </template>
          <span>Edit the Sim</span>
        </v-tooltip>
      </div>
    </SummaryElement>

    <!-- Bill payers -->
    <SummaryElement read-only label="Bill payers" align="start">
      <div
        v-for="(billPayer, i) in billPayers"
        :key="i"
        class="d-flex align-center"
      >
        {{ billPayer.name }}
      </div>
    </SummaryElement>

    <!-- SIM providers -->
    <SummaryElement
      v-if="simProviders.length"
      read-only
      label="SIM providers"
      align="start"
    >
      <div
        v-for="(simProvider, i) in simProviders"
        :key="i"
        class="d-flex align-center"
      >
        {{ simProvider.name }}
      </div>
    </SummaryElement>

    <!-- Router UserID -->
    <SummaryElement
      read-only
      label="Router UserID"
      :value="router.routerUserId"
      is-copyable
    />

    <!-- Password -->
    <SummaryElement
      :value="router.routerPassword"
      read-only
      label="Password"
      is-password
      is-copyable
    />

    <!-- Serial Number -->
    <SummaryElement
      read-only
      label="Serial Number"
      :value="router.serialNumber"
      is-copyable
    >
      <template #value-text>
        <span
          class="primary--text cursor-pointer"
          @click="routerStore.openDialog(router)"
        >
          {{ router.serialNumber }}
        </span>
      </template>
      <template #append>
        <OpenInNewWindowButton
          :target-link="`${router.rmsUrl}`"
          hint="Open In RMS"
        />
      </template>
    </SummaryElement>

    <!-- Power Type -->
    <SummaryElement read-only label="Power Type">
      <div class="d-flex align-center">
        {{ router.powerType || "-" }}
      </div>
    </SummaryElement>

    <!-- Port -->
    <ERow no-gutters>
      <div class="d-flex justify-start">
        <div class="summary-element__label subtitle-2 mr-2 pl-1 pt-1">Port</div>

        <CameraSummaryPortElement
          v-model="routerPortModel"
          class="ml-n3"
          label="HTTP"
          :port="routerPortModel.value"
          :host="vpnServerUrl"
          read-only
          dense
        />
      </div>
    </ERow>

    <!-- NetBird port -->
    <ERow v-if="cameraDialogStore.isKit" no-gutters>
      <div class="d-flex justify-start">
        <div class="summary-element__label subtitle-2 mr-2 pl-1 pt-1">
          NetBird Port
        </div>

        <CameraSummaryPortElement
          v-model="netBirdRouterPortModel"
          class="ml-n3"
          label="HTTP"
          :scheme="camera.cameraScheme"
          :host="netBirdRouterHost"
          :port="netBirdRouterPort"
          read-only
          dense
        />
      </div>
    </ERow>

    <!-- Battery -->
    <SummaryElement v-if="showBatteryField" read-only label="Battery">
      <div class="primary--text cursor-pointer" @click="openBatteryReadings">
        {{ batteryLabel }}
      </div>
      <BatteryReadingsDialog
        :id="camera.exid"
        :show-dialog="batteryDialog"
        @closed="batteryDialog = false"
      />
    </SummaryElement>
    <SmsHistoryDialog
      v-model="smsHistoryDialog"
      :show-header="showSmsHistoryHeader"
      :sim="selectedSim"
      :selected-sim="editedSim"
    />
    <SimsDialog
      :edited-sim="editedSim"
      :edit-summary-router-sim="true"
      @clear-summary-sim="clearSim"
      @refetch-router-details="$emit('refetch-router-details')"
    />
  </div>
</template>

<script lang="ts">
import SummaryElement from "@/components/cameraTabs/summary/SummaryElement"
import SmsHistoryDialog from "@/components/sims/SmsHistoryDialog"
import SimsDialog from "@/components/sims/SimsDialog"
import CopyToClipboardBtn from "@evercam/shared/components/CopyToClipboardBtn"
import BatteryReadingsDialog from "@/components/batteries/BatteryReadingsDialog"
import CameraSummaryPortElement from "@/components/cameraTabs/summary/CameraSummaryPortElement"
import OpenInNewWindowButton from "@/components/OpenInNewWindowButton"
import Vue, { PropType } from "vue"
import {
  Router,
  PowerType,
  Sim,
  RouterBillPayer,
  SimProvider,
} from "@evercam/shared/types/router"
import { AdminApi } from "@evercam/shared/api/adminApi"
import { mapStores } from "pinia"
import { useAccountStore } from "@/stores/account"
import { useCameraDialogStore } from "@/stores/cameraDialog"
import { useRouterStore } from "@/stores/router"
import { PERMISSIONS } from "~/constants/permissions"
import vpnServers from "@evercam/shared/constants/vpnServers"

export default Vue.extend({
  name: "CameraSummaryRouter",
  components: {
    SummaryElement,
    SmsHistoryDialog,
    CopyToClipboardBtn,
    BatteryReadingsDialog,
    CameraSummaryPortElement,
    OpenInNewWindowButton,
    SimsDialog,
  },
  props: {
    router: {
      type: [Object, null] as PropType<Router | null>,
      default: () => ({} as Router),
    },
    camera: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedSim: null,
      smsHistoryDialog: false,
      editSimDialog: false,
      batteryDialog: false,
      editedSim: {
        id: null,
        number: "",
        simProvider: "",
        billPayer: "",
        pin1: "",
        pin2: "",
        puk: "",
        iccid: "",
        description: "",
      },
      defaultSim: {
        id: null,
        number: "",
        simProvider: "",
        billPayer: "",
        pin1: "",
        pin2: "",
        puk: "",
        iccid: "",
        description: "",
      },
      routerPortModel: {
        key: "routerHttpPort",
        label: "Port",
        value: this.router.routerHttpPort,
        type: "TEXT_FIELD",
      },
      batteryId: 0,
    }
  },
  computed: {
    ...mapStores(useAccountStore, useCameraDialogStore, useRouterStore),
    canEditCameras() {
      return this.$permissions.user.can(PERMISSIONS.CAMERAS.UPDATE)
    },
    netBirdRouterHost(): string {
      const cameraHost = this.cameraDialogStore.camera?.cameraHost
      const netBirdUrlRegex = /-(\d+)-kit-/g

      return cameraHost.replace(netBirdUrlRegex, "-1-kit-")
    },
    netBirdRouterPort(): number {
      return this.cameraDialogStore.camera?.cameraHttpPort ?? 443
    },
    netBirdRouterPortModel(): Record<string, unknown> {
      return {
        key: "stub_netBirdRouterPort",
        label: "NetBird Port",
        value: this.netBirdRouterPort,
        type: "TEXT_FIELD",
      }
    },
    showBatteryField(): boolean {
      return (
        !!this.router?.battery && this.router?.powerType !== PowerType.Mains
      )
    },
    batteryLabel(): string {
      if (this.router.battery) {
        return `${this.router.battery.lastVoltage}V (${this.$moment
          .tz(this.router.battery.lastSeen, this.camera.timezone)
          .fromNow()})`
      }

      return "-"
    },
    sims(): Sim[] {
      return this.router?.sims || []
    },
    showSmsHistoryHeader(): boolean {
      return this.accountStore.email === "oussama.boumaad@evercam.io"
    },
    billPayers(): RouterBillPayer[] {
      return this.router?.billPayers || []
    },
    simProviders(): SimProvider[] {
      return this.router?.simProviders || []
    },
    vpnServerUrl(): string {
      const vpnServer = vpnServers.find(
        (el) => el.name === this.router.vpnServer
      )

      return vpnServer.value
    },
  },
  watch: {
    router: {
      deep: true,
      handler() {
        this.smsHistoryDialog = false
        this.routerPortModel = {
          key: "routerHttpPort",
          label: "Port",
          value: this.router.routerHttpPort,
          type: "TEXT_FIELD",
        }
      },
    },
    sim: {
      handler() {
        this.smsHistoryDialog = false
      },
    },
    async routerPortModel({ value }) {
      if (!value || this.router.routerHttpPort === value) {
        return
      }
      const router = { ...this.router }
      router.routerHttpPort = value
      await this.updateRouter(router)
      this.$emit("router-change", { routerHttpPort: value })
    },
  },
  methods: {
    clearSim() {
      this.editedSim = { ...this.defaultItem }
    },
    inspectSim(sim) {
      this.selectedSim = sim
      this.editedSim.simProvider = this.simProviders[0]?.name
      this.editedSim.billPayer = this.billPayers[0]?.name
      this.smsHistoryDialog = true
    },
    editSim(sim) {
      this.editedSim = { ...sim }
      this.editedSim.simProvider = this.simProviders[0]?.name
      this.editedSim.billPayer = this.billPayers[0]?.name
      this.editSimDialog = true
    },
    openBatteryReadings() {
      this.batteryId = this.router.id
      this.batteryDialog = true
    },
    async updateRouter(router) {
      try {
        await AdminApi.routers.updateRouter(router.id, router)
      } catch (error) {
        this.$notifications.error({
          text: "Failed to update the router!",
          error,
        })
      }
    },
  },
})
</script>
